import React, { useState, useEffect } from 'react';
import axios from 'axios';
import instance from '../../API';
import {
    Box,
    AppBar,
    Toolbar,
    Typography,
    Tabs,
    Tab,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FilledInput,
    MenuItem,
    Select,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Avatar,
    Chip,
    useTheme,
    alpha,
    Autocomplete
  } from '@mui/material';
  import {
    Person,
    BusinessCenter,
    Assessment,
    Receipt,
    ShoppingCart,
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Close as CloseIcon,
    Details as DetailsIcon,
    Check,
    LocalShipping,
    Airlines
  } from '@mui/icons-material';

const CRMPage = () => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [users, setUsers] = useState([]);
  const [openOpportunityDialog, setOpenOpportunityDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedCarriers, setSelectedCarriers] = useState({});

  const [formData, setFormData] = useState({
    userType: '',
    firstName: '',
    lastName: '',
    companyName: '',
    billingAddress1: '',
    billingAddress2: '',
    billingZip: '',
    billingCity: '',
    billingState: '',
    email: '',
    phoneNumber: '',
    directLine: '',
    shippingAddress1: '',
    shippingAddress2: '',
    shippingZip: '',
    shippingCity: '',
    shippingState: ''
  });

  const [opportunities, setOpportunities] = useState([
    {
      id: 0,
      user: '',
      shipmentType: '', // new field for domestic/international
      loadType: '',
      subLoadType: '', // new field for air/ocean options
      specificLoadType: '', // new field for final specific options
      weight: '',
      dimensions: '',
      pickupAddress: '',
      deliveryAddress: '',
      commodityName: '',
      status: ''
    }
  ]);

  const [opportunityForm, setOpportunityForm] = useState({
    user: null,
    shipmentType: '', // new field for domestic/international
    loadType: '',
    subLoadType: '', // new field for air/ocean options
    specificLoadType: '', // new field for final specific options
    weight: '',
    dimensions: '',
    pickupAddress: '',
    deliveryAddress: '',
    commodityName: ''
  });



  const shipmentTypes = ['Domestic', 'International'];
  
  const domesticLoadTypes = [
    'LTL (Pallets)',
    'LTL (Loose)',
    'LTL (Blanket Wrap Air Ride)',
    'TL (Box Truck)',
    'TL (54 feet Trailer)',
    'White Glove',
    'Blind Shipment',
    'SPD'
  ];

  const internationalTypes = ['Air', 'Ocean'];

  const airOptions = [
    'Freight',
    'Small Parcel',
    'Compliance',
    'Customs'
  ];

  const oceanOptions = [
    'LCL (Import)',
    'FCL (Import/Export)',
    'Compliance',
    'Customs'
  ];


  useEffect(()=>{
    
    const getUserData = async () => {
        console.log("getting User Data");
        const reqData = {
          "dataType": "UserData",
          "ID" : "All"
        }
        try {
        
          //const response = await axios.post('https://the-crm-backend-4sst.onrender.com/getData', reqData);      
          const response = await instance.post('/getData', reqData);
          console.log((response));
          setUsers(JSON.parse(response.data.data));
          console.log(users[0])
          //setShowUsers(1);
        } catch (error) {
          //window.alert('Masla');
          console.error(error);
        }
    
      }

      const getOppData = async () => {
        console.log("getting Opp Data");
        const reqData = {
          "dataType": "OppData",
          "ID" : "All"
        }
        try {
          const response = await instance.post('/getData', reqData);
          console.log((response));
          setOpportunities(JSON.parse(response.data.data));
          //setShowUsers(1);
        } catch (error) {
          //window.alert('Masla');
          console.error(error);
        }
        // handleSelectedCarrierInfo();
      }
      if(tabValue === 0){
        //setShowUsers(0);
        getUserData();
      }
      else if(tabValue === 1 || tabValue === 2){
        getOppData();
      }

  },[tabValue])


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    const newUser = {
      id: users.length + 1,
      ...formData
    };
    setUsers(prev => [...prev, newUser]);
    setOpenDialog(false);

    const UserData = {
        "id" : users.length + 1,
        "userType": formData.userType,
        "firstName": formData.firstName,
        "lastName": formData.lastName,
        "email": formData.email,
        "companyName": formData.companyName,
        "phoneNumber": formData.phoneNumber,
        "directLine": formData.directLine,
        "billingAddress": formData.billingAddress1 + " " + formData.billingAddress2,
        "billingZip": formData.billingZip,
        "billingCity": formData.billingCity,
        "billingState": formData.billingState,
        "shippingAddress" : formData.shippingAddress1 + " " + formData.shippingAddress2,
        "shippingZip": formData.shippingZip,
        "shippingCity": formData.shippingCity,
        "shippingState" : formData.shippingState,
      }
    try {
        await instance.post('/CreateUser', UserData);
        window.alert('Data has been submitted');
      } catch (error) {
        window.alert('Data not submitted');
        console.error(error);
      }

      setFormData({
        userType: '',
        firstName: '',
        lastName: '',
        companyName: '',
        billingAddress1: '',
        billingAddress2: '',
        billingZip: '',
        billingCity: '',
        billingState: '',
        email: '',
        phoneNumber: '',
        directLine: '',
        shippingAddress1: '',
        shippingAddress2: '',
        shippingZip: '',
        shippingCity: '',
        shippingState: ''
      });

  };



  const handleOpportunityChange = (field, value) => {
    const updates = { [field]: value };
    
    // Reset dependent fields when parent selection changes
    if (field === 'shipmentType') {
      updates.loadType = '';
      updates.subLoadType = '';
      updates.specificLoadType = '';
    } else if (field === 'loadType') {
      updates.subLoadType = '';
      updates.specificLoadType = '';
    } else if (field === 'subLoadType') {
      updates.specificLoadType = '';
    }

    setOpportunityForm(prev => ({
      ...prev,
      ...updates
    }));
  };

  const handleOpportunitySubmit = async () => {
    const newOpportunity = {
      id: opportunities.length + 1,
      ...opportunityForm,
      status: 'New'
    };
    setOpportunities(prev => [...prev, newOpportunity]);
    setOpenOpportunityDialog(false);

    const OppData = {
        "id" : opportunities.length + 1,
        "shipmentType" : opportunityForm.shipmentType,
        "user": opportunityForm.user,
        "loadType": opportunityForm.loadType,
        "subLoadType": opportunityForm.subLoadType,
        "specificLoadType": opportunityForm.specificLoadType,
        "weight": opportunityForm.weight,
        "dimensions": opportunityForm.dimensions,
        "pickupAddress": opportunityForm.pickupAddress,
        "deliveryAddress": opportunityForm.deliveryAddress,
        "commodityName": opportunityForm.commodityName
      }
      console.log(OppData)
    try {
        await instance.post('/CreateOpportunity', OppData);
        window.alert('Data has been submitted');
      } catch (error) {
        window.alert('Data not submitted');
        console.error(error);
      }

      setOpportunityForm({
        user: null,
        loadType: '',
        weight: '',
        dimensions: '',
        pickupAddress: '',
        deliveryAddress: '',
        commodityName: ''
      });
  };

  const handleRowClick = (item, type) => {
    if (type === 'user') {
      setSelectedUser(item);
    } else {
      setSelectedOpportunity(item);
    }
    setOpenDetailsDialog(true);
  };

  const renderLoadTypeSelections = () => (
    <>
      <Grid item xs={12}>
        <TextField
          select
          fullWidth
          label="Shipment Type"
          value={opportunityForm.shipmentType}
          onChange={(e) => handleOpportunityChange('shipmentType', e.target.value)}
        >
          {shipmentTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {opportunityForm.shipmentType === 'Domestic' && (
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Load Type"
            value={opportunityForm.loadType}
            onChange={(e) => handleOpportunityChange('loadType', e.target.value)}
          >
            {domesticLoadTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}

      {opportunityForm.shipmentType === 'International' && (
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Service Type"
            value={opportunityForm.loadType}
            onChange={(e) => handleOpportunityChange('loadType', e.target.value)}
          >
            {internationalTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}

      {opportunityForm.shipmentType === 'International' && opportunityForm.loadType === 'Air' && (
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Air Service Type"
            value={opportunityForm.subLoadType}
            onChange={(e) => handleOpportunityChange('subLoadType', e.target.value)}
          >
            {airOptions.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}

      {opportunityForm.shipmentType === 'International' && opportunityForm.loadType === 'Ocean' && (
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Ocean Service Type"
            value={opportunityForm.subLoadType}
            onChange={(e) => handleOpportunityChange('subLoadType', e.target.value)}
          >
            {oceanOptions.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
    </>
  );

  const DetailsDialog = () => {
    const isUser = !!selectedUser;
    const item = selectedUser || selectedOpportunity;

    if (!item) return null;

    return (
      <Dialog
        open={openDetailsDialog}
        onClose={() => {
          setOpenDetailsDialog(false);
          setSelectedUser(null);
          setSelectedOpportunity(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2, pb: 1 }}>
          <Typography variant="h6">
            {isUser ? 'User Details' : 'Opportunity Details'}
          </Typography>
          <IconButton
            onClick={() => {
              setOpenDetailsDialog(false);
              setSelectedUser(null);
              setSelectedOpportunity(null);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {isUser ? (
              // User Details Form
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={item.firstName}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    value={item.lastName}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Company"
                    value={item.companyName}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Type"
                    value={item.userType}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    value={item.email}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    value={item.phoneNumber}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Direct Line"
                    value={item.directLine}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Billing Address"
                    value={`${item.billingAddress}`}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Billing Zip Code"
                    value={item.billingZip}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Billing City"
                    value={item.billingCity}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Billing State"
                    value={item.billingState}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Shipping Address"
                    value={`${item.shippingAddress}`}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Shipping Zip Code"
                    value={item.shippingZip}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Shipping City"
                    value={item.shippingCity}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Shipping State"
                    value={item.shippingState}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </>
            ) : (
              // Opportunity Details Form
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Customer"
                    value={`${item.user.firstName} ${item.user.lastName} - ${item.user.companyName}`}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                {(item.shipmentType === 'Domestic') ? 
                <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Shipment Type"
                    value={item.shipmentType}
                    InputProps={{ readOnly: true }}
                  />
                </Grid> 
                  <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Load Type"
                    value={item.loadType}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                </>
                : 
                <>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Shipment Type"
                    value={item.shipmentType}
                    InputProps={{ readOnly: true }}
                  />
                </Grid> 
                  <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Route"
                    value={item.loadType}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Load Type"
                    value={item.subLoadType}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                </>
                }
                

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Weight"
                    value={`${item.weight} lbs`}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Dimensions"
                    value={item.dimensions}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Pick up Address"
                    value={item.pickupAddress}
                    InputProps={{ readOnly: true }}
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Delivery Address"
                    value={item.deliveryAddress}
                    InputProps={{ readOnly: true }}
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Commodity Name"
                    value={item.commodityName}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Status"
                    value={item.status}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={() => {
              setOpenDetailsDialog(false);
              setSelectedUser(null);
              setSelectedOpportunity(null);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };


  const tabItems = [
    { label: 'Users', icon: <Person /> },
    { label: 'Sales Opportunities', icon: <BusinessCenter /> },
    { label: 'Sales Estimates', icon: <Assessment /> },
    { label: 'Sales Invoice', icon: <Receipt /> },
    { label: 'Purchasing Invoice', icon: <ShoppingCart /> }
  ];

  const UsersList = () => {

        return (
            <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Users Management
                </Typography>
                <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenDialog(true)}
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.9),
                    }
                }}
                >
                Create New User
                </Button>
            </Box>

            <TableContainer component={Paper} elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>User</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Type</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Company</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Contact</Typography></TableCell>
                    {/* <TableCell align="right">Actions</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { users.map((user) => (
                    <TableRow key={user.id} 
                              hover 
                              onClick={() => handleRowClick(user, 'user')}
                              sx={{ cursor: 'pointer' }}>
                        <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Avatar sx={{ bgcolor: user.type === 'customer' ? 'primary.main' : 'secondary.main' }}>
                            {user.firstName[0]}{user.lastName[0]}
                            </Avatar>
                            <Box>
                            <Typography variant="subtitle2">{`${user.firstName} ${user.lastName}`}</Typography>
                            <Typography variant="body2" color="text.secondary">{user.email}</Typography>
                            </Box>
                        </Box>
                        </TableCell>
                        <TableCell>
                        <Chip
                            label={user.userType}
                            size="small"
                            color={user.userType === 'customer' ? 'primary' : 'secondary'}
                            sx={{ textTransform: 'capitalize' }}
                        />
                        </TableCell>
                        <TableCell>{user.companyName}</TableCell>
                        <TableCell>{user.phoneNumber}</TableCell>
                        {/* <TableCell align="right">
                        <IconButton size="small" color="primary">
                            <DetailsIcon fontSize="small" />
                        </IconButton>
                        <IconButton size="small" color="error">
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                        </TableCell> */}
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            </Box>
        );
    }

const OpportunitiesList = () => {
  
  const [status, setStatus] = useState('');
  // const checkStatus = (opp)=>{
  //   if(opp.carrierEstimates != null){
  //     opp.status
  //   }
  //   else{
  //     setStatus('No Action Taken');
  //   }
  // }

  return(
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Sales Opportunities
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenOpportunityDialog(true)}
              sx={{
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.9),
                }
              }}
            >
              Create New Opportunity
            </Button>
          </Box>
    
          <TableContainer component={Paper} elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Customer</Typography></TableCell>
                  <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Load Type</Typography></TableCell>
                  <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Weight/Dimensions</Typography></TableCell>
                  <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Commodity</Typography></TableCell>
                  <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Status</Typography></TableCell>
                  {/* <TableCell align="right">Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {(opportunities[0].id > 0) ? opportunities.map((opportunity) => (
                  <TableRow key={opportunity.id} 
                            hover
                            onClick={() => handleRowClick(opportunity, 'opportunity')}
                            sx={{ cursor: 'pointer' }}>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                      {opportunity.user.firstName[0]}{opportunity.user.lastName[0]}
                    </Avatar>
                    <Box>
                      <Typography variant="subtitle2">
                        {`${opportunity.user.firstName} ${opportunity.user.lastName}`}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {opportunity.user.companyName}
                      </Typography>
                    </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {(opportunity.shipmentType === "Domestic" ) ?
                        <LocalShipping fontSize="small" color="action" />:
                        <Airlines fontSize="small" color="action" />
                      }
                        <Box>
                        <Typography variant="subtitle2">{opportunity.shipmentType}</Typography>                   
                        <Typography variant="body2" color="text.secondary">
                          {opportunity.loadType}
                        </Typography>
                        {(opportunity.shipmentType === "International" ) ?
                        <Typography variant="caption text" color="text.secondary">
                          {opportunity.subLoadType}
                        </Typography> : null}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{`${opportunity.weight} lbs`}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {opportunity.dimensions}
                      </Typography>
                    </TableCell>
                    <TableCell>{opportunity.commodityName}</TableCell>
                    <TableCell>
                      {(opportunity.carrierEstimates) ? <Typography
                                                                  sx={{
                                                                    backgroundColor: 'green',
                                                                    color: 'black',
                                                                    padding: '4px 8px',
                                                                    borderRadius: 1
                                                                  }}
                                                                >
                                                                  Action Taken
                                                                </Typography> : 
                                                                <Typography
                                                                  sx={{
                                                                    backgroundColor: 'red',
                                                                    color: 'black',
                                                                    padding: '4px 8px',
                                                                    borderRadius: 1
                                                                  }}
                                                                >
                                                                  No Action Taken
                                                                </Typography>}
                    </TableCell>
                    {/* <TableCell align="right">
                      <IconButton size="small" color="primary">
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton size="small" color="error">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                )): null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );}

      const SalesEstimateTab = ({ opportunities, setOpportunities }) => {
        const [openEstimateDialog, setOpenEstimateDialog] = useState(false);
        const [selectedOpportunity, setSelectedOpportunity] = useState(null);
        const [newCarrierName, setNewCarrierName] = useState('');
        const [newCarrierCost, setNewCarrierCost] = useState('');
        
        // opportunities.map((opp)=>{
        //   (opp.selectedCarrier !== null) ? 
        //   setSelectedCarriers({
        //     ...selectedCarriers,
        //     [opp.id] : JSON.stringify(opp.selectedCarrier)}) : setSelectedCarriers(...selectedCarriers)
        // })

        const handleAddEstimate = (opportunity) => {
          setSelectedOpportunity(opportunity);
          setOpenEstimateDialog(true);
        };
      
        const handleEstimateSubmit = async () => {
          // Update the opportunity with the new carrier estimate
          const updatedOpportunities = opportunities.map((opp) => {
            if (opp.id === selectedOpportunity.id) {
              return {
                ...opp,
                carrierEstimates: [
                  ...(opp.carrierEstimates || []),
                  { name: newCarrierName, cost: newCarrierCost }
                ]
              };
            }
            return opp;
          });
          // Update the opportunities state with the new data

          setOpportunities(updatedOpportunities);
          console.log(updatedOpportunities);
          setOpenEstimateDialog(false);
          setNewCarrierName('');
          setNewCarrierCost('');

          try {
            const UpdateOpp = {
              'theID' : selectedOpportunity.id,
              'theOpportunities' : updatedOpportunities
            }
            const response = await instance.post('/updateOpportunityData', UpdateOpp);
            console.log((response));
            // window.alert('Data has been submitted');
          } catch (error) {
            window.alert('Data not submitted');
            console.error(error);
          }  
     
        };

       const handleCarrierChange =  async (opportunity, value) => {
          setSelectedCarriers({
            ...selectedCarriers,
            [opportunity.id]: value,
          });
          console.log(selectedCarriers);
          setSelectedOpportunity(opportunity);
          const updatedOpportunities = opportunities.map((opp) => {
            //console.log(opp)
            if (opp.id === opportunity.id) {
              return {
                ...opp,
                selectedCarrier: JSON.parse(value)     
              };
            }
            console.log(opp)
            return opp;
          });

          // setOpportunities(updatedOpportunities);
          console.log(updatedOpportunities);

          try {
            const UpdateOpp = {
              'theID' : opportunity.id,
              'theOpportunities' : updatedOpportunities
            }
            console.log(UpdateOpp);
            const response = await instance.post('/updateOpportunityData', UpdateOpp);
            console.log((response));
            // window.alert('Data has been submitted');
          } catch (error) {
            window.alert('Data not submitted');
            console.error(error);
          }  


          
        };
      
        const handleDeleteEstimate = (opportunity, index) => {
          const updatedOpportunities = opportunities.map((opp) => {
            if (opp.id === opportunity.id) {
              return {
                ...opp,
                carrierEstimates: opp.carrierEstimates.filter((_, i) => i !== index)
              };
            }
            return opp;
          });
          setOpportunities(updatedOpportunities);
        };

      
        return (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Sales Estimates
              </Typography>
              {/* <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenEstimateDialog(true)}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.9)
                  }
                }}
              >
                Add Carrier Estimate
              </Button> */}
            </Box>
      
            <TableContainer component={Paper} elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }} >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Customer</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Load Type</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Weight/Dimensions</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Commodity</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Carrier Details</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Actions</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Status</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(opportunities[0].id > 0) ? opportunities.map((opportunity) => (
                    <TableRow key={opportunity.id}>
                      <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                          {opportunity.user.firstName[0]}{opportunity.user.lastName[0]}
                        </Avatar>
                        <Box>
                      <Typography variant="body1">{`${opportunity.user.firstName} ${opportunity.user.lastName}`}</Typography>
                      <Typography variant="body2" color="text.secondary">
                          {opportunity.user.companyName}
                        </Typography>
                        </Box>
                        </Box>                      
                        </TableCell>
                      <TableCell>
                        <Typography variant="body1">{opportunity.shipmentType}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {opportunity.loadType}
                        </Typography>
                        {opportunity.shipmentType === 'International' && (
                          <Typography variant="body2" color="text.secondary">
                            {opportunity.subLoadType}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{`${opportunity.weight} lbs`}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {opportunity.dimensions}
                        </Typography>
                      </TableCell>
                      <TableCell>
                      <Typography variant="body1">{`${opportunity.commodityName}`}</Typography>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {(opportunity.userPayment) ? 
                          <TextField
                            fullWidth
                            value={ `Carrier : ${opportunity.selectedCarrier.name} at $${opportunity.selectedCarrier.cost}`}
                            InputProps={{ readOnly: true }}
                          /> :
                          <Select
                            value={(opportunity.selectedCarrier) ? JSON.stringify(opportunity.selectedCarrier) : selectedCarriers[opportunity.id] || ''}
                            onChange={(e) => {
                              handleCarrierChange(opportunity, e.target.value);
                              }
                            }
                            native
                            inputProps={{ 'aria-label': 'Carrier' }}
                          >
                            <option value="">Select a carrier</option>
                            {(opportunity.carrierEstimates || []).map((carrier, index) => (
                              <option key={index} value={JSON.stringify(carrier)} >
                                {`${carrier.name} quote $${carrier.cost}`}
                              </option>
                            ))
                            }
                          </Select>
                          }
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAddEstimate(opportunity)}
                          size="small"
                        >
                          Add Estimate
                        </Button>
                      </TableCell>
                      <TableCell>
                      {(opportunity.userPayment) ? <Typography
                                                                  sx={{
                                                                    backgroundColor: 'green',
                                                                    color: 'black',
                                                                    padding: '4px 8px',
                                                                    borderRadius: 1
                                                                  }}
                                                                >
                                                                  Finalized
                                                                </Typography> : 
                                                                <Typography
                                                                  sx={{
                                                                    backgroundColor: 'red',
                                                                    color: 'black',
                                                                    padding: '4px 8px',
                                                                    borderRadius: 1
                                                                  }}
                                                                >
                                                                  In Progress
                                                                </Typography>}
                    </TableCell>
                    </TableRow>
                  )) : null}
                </TableBody>
              </Table>
            </TableContainer>
      
            {/* Add Carrier Estimate Dialog */}
            <Dialog
              open={openEstimateDialog}
              onClose={() => setOpenEstimateDialog(false)}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle sx={{ m: 0, p: 2, pb: 1 }}>
                <Typography variant="h6">Add Carrier Estimate</Typography>
                <IconButton
                  onClick={() => setOpenEstimateDialog(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Box>
                  <TextField
                    fullWidth
                    label="Carrier Name"
                    value={newCarrierName}
                    onChange={(e) => setNewCarrierName(e.target.value)}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="Carrier Cost"
                    value={newCarrierCost}
                    onChange={(e) => setNewCarrierCost(e.target.value)}
                    type="number"
                  />
                </Box>
              </DialogContent>
              <DialogActions sx={{ p: 2 }}>
                <Button onClick={() => setOpenEstimateDialog(false)}>Cancel</Button>
                <Button variant="contained" onClick={handleEstimateSubmit}>
                  Save Estimate
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        );
      };

      const SalesInvoiceTab = ({ opportunities, setOpportunities }) => {
        const [openCustPaymentDialog, setOpenCustPaymentDialog] = useState(false);
        const [newCustomerPayment, setNewCustomerPayment] = useState('');
        const [selectedOpportunity, setSelectedOpportunity] = useState(null);

        const handleAddPayment = (opportunity)=>{
          setSelectedOpportunity(opportunity);
          setOpenCustPaymentDialog(true);
        }

        const handlePaymentSubmit = async () => {
          // Update the opportunity with the new customer payment
          const updatedOpportunities = opportunities.map((opp) => {
            if (opp.id === selectedOpportunity.id) {
              return {
                ...opp,
                userPayment: newCustomerPayment
              };
            }
            return opp;
          });
          // Update the opportunities state with the new data

          setOpportunities(updatedOpportunities);
          console.log(updatedOpportunities);
          setOpenCustPaymentDialog(false);
          setNewCustomerPayment('');
          try {
            const UpdateOpp = {
              'theID' : selectedOpportunity.id,
              'theOpportunities' : updatedOpportunities
            }
            const response = await instance.post('/updateOpportunityData', UpdateOpp);
            console.log((response));
            // window.alert('Data has been submitted');
          } catch (error) {
            window.alert('Data not submitted');
            console.error(error);
          }  
     
        };

        return (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Sales Invoice
              </Typography>
            </Box>
    
            <TableContainer component={Paper} elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Customer</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Load Type</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Weight/Dimensions</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Commodity</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Selected Carrier</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Customer Payment</Typography></TableCell>
                    <TableCell><Typography variant="body1" sx={{ fontWeight: 600 }}>Action</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(opportunities[0].id > 0) ? opportunities.map((opportunity) => (
                    <TableRow key={opportunity.id}>
                      <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                          {opportunity.user.firstName[0]}{opportunity.user.lastName[0]}
                        </Avatar>
                        <Box>
                      <Typography variant="body1">{`${opportunity.user.firstName} ${opportunity.user.lastName}`}</Typography>
                      <Typography variant="body2" color="text.secondary">
                          {opportunity.user.companyName}
                        </Typography>
                        </Box>
                        </Box>  
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{opportunity.shipmentType}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {opportunity.loadType}
                        </Typography>
                        {opportunity.shipmentType === 'International' && (
                          <Typography variant="body2" color="text.secondary">
                            {opportunity.subLoadType}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{`${opportunity.weight} lbs`}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {opportunity.dimensions}
                        </Typography>
                      </TableCell>
                      <TableCell>
                      <Typography variant="body1">{`${opportunity.commodityName}`}</Typography>
                      </TableCell>
                      <TableCell>
                      { (opportunity.selectedCarrier) ? <div>
                      <Typography variant="body1">{`Name : ${opportunity.selectedCarrier.name}`}</Typography>
                      <Typography variant="body1">{`Cost : $${opportunity.selectedCarrier.cost}`}</Typography>
                      </div> : ''
                      }
                      </TableCell>
                      <TableCell>
                      <Typography variant="body1">{(opportunity.userPayment)?`$${opportunity.userPayment}`: `Not Entered `}</Typography>
                      </TableCell>
                      <TableCell>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAddPayment(opportunity)}
                            size="small"
                          >
                            Add Customer Payment
                          </Button>
                      </TableCell>
                    </TableRow>
                  )) : null}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Add Customer Payment Dialog */}
            <Dialog
              open={openCustPaymentDialog}
              onClose={() => setOpenCustPaymentDialog(false)}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle sx={{ m: 0, p: 2, pb: 1 }}>
                <Typography variant="h6">Add Carrier Estimate</Typography>
                <IconButton
                  onClick={() => setOpenCustPaymentDialog(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="Customer Payment"
                    value={newCustomerPayment}
                    onChange={(e) => setNewCustomerPayment(e.target.value)}
                    type="number"
                  />
                </Box>
              </DialogContent>
              <DialogActions sx={{ p: 2 }}>
                <Button onClick={() => setOpenCustPaymentDialog(false)}>Cancel</Button>
                <Button variant="contained" onClick={handlePaymentSubmit}>
                  Save Payment
                </Button>
              </DialogActions>
            </Dialog>

          </Box>
        );
      };


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar position="static" elevation={0} sx={{ bgcolor: 'background.paper', borderBottom: `1px solid ${theme.palette.divider}` }}>
        <Toolbar>
          <Typography variant="h5" sx={{ color: 'text.primary', fontWeight: 600 }}>
            CRM Dashboard
          </Typography>
        </Toolbar>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          sx={{
            px: 2,
            '& .MuiTab-root': {
              minHeight: 64,
              textTransform: 'none'
            }
          }}
        >
          {tabItems.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              icon={tab.icon}
              iconPosition="start"
            />
          ))}
        </Tabs>
      </AppBar>

      <Box sx={{ p: 3, bgcolor: 'grey.50', flexGrow: 1 }}>
        {tabValue === 0 && <UsersList />}
        {tabValue === 1 && <OpportunitiesList />}
        {tabValue === 2 && <SalesEstimateTab opportunities={opportunities} setOpportunities={setOpportunities} />}
        {tabValue === 3 && <SalesInvoiceTab opportunities={opportunities} setOpportunities={setOpportunities} />}
        {tabValue > 3 && (
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6">{tabItems[tabValue].label}</Typography>
            <Typography color="text.secondary" sx={{ mt: 2 }}>
              Content for {tabItems[tabValue].label} will be displayed here.
            </Typography>
          </Paper>
        )}
      </Box>
      <DetailsDialog />
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2, pb: 1 }}>
          <Typography variant="h6">Create New User</Typography>
          <IconButton
            onClick={() => setOpenDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="User Type"
                name="userType"
                value={formData.userType}
                onChange={handleChange}
              >
                <MenuItem value="customer">Customer</MenuItem>
                <MenuItem value="vendor">Vendor</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Company Name"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ mb: 2 }}>Billing Address</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    name="billingAddress1"
                    value={formData.billingAddress1}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    name="billingAddress2"
                    value={formData.billingAddress2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Zip Code"
                    name="billingZip"
                    value={formData.billingZip}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="City"
                    name="billingCity"
                    value={formData.billingCity}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="State"
                    name="billingState"
                    value={formData.billingState}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Direct Line"
                name="directLine"
                value={formData.directLine}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ mb: 2 }}>Shipping Address</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    name="shippingAddress1"
                    value={formData.shippingAddress1}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    name="shippingAddress2"
                    value={formData.shippingAddress2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Zip Code"
                    name="shippingZip"
                    value={formData.shippingZip}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="City"
                    name="shippingCity"
                    value={formData.shippingCity}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="State"
                    name="shippingState"
                    value={formData.shippingState}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button 
            variant="contained"
            onClick={handleSubmit}
          >
            Create User
          </Button>
        </DialogActions>
      </Dialog>

      

    {/* Create Opportunity Dialog */}
    <Dialog 
        open={openOpportunityDialog} 
        onClose={() => setOpenOpportunityDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2, pb: 1 }}>
          <Typography variant="h6">Create New Opportunity</Typography>
          <IconButton
            onClick={() => setOpenOpportunityDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                options={users}
                getOptionLabel={(option) => 
                  `${option.firstName} ${option.lastName} - ${option.companyName} (ID: ${option.id})`
                }
                value={opportunityForm.user}
                onChange={(event, newValue) => {
                  handleOpportunityChange('user', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select User"
                    fullWidth
                  />
                )}
              />
            </Grid>
            
            {renderLoadTypeSelections()}

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Weight (lbs)"
                value={opportunityForm.weight}
                onChange={(e) => handleOpportunityChange('weight', e.target.value)}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Dimensions (HxLxW)"
                value={opportunityForm.dimensions}
                onChange={(e) => handleOpportunityChange('dimensions', e.target.value)}
                placeholder="Example: 48x40x48"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Pick up Address"
                value={opportunityForm.pickupAddress}
                onChange={(e) => handleOpportunityChange('pickupAddress', e.target.value)}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Delivery Address"
                value={opportunityForm.deliveryAddress}
                onChange={(e) => handleOpportunityChange('deliveryAddress', e.target.value)}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Commodity Name"
                value={opportunityForm.commodityName}
                onChange={(e) => handleOpportunityChange('commodityName', e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={() => setOpenOpportunityDialog(false)}>Cancel</Button>
          <Button 
            variant="contained"
            onClick={handleOpportunitySubmit}
          >
            Create Opportunity
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CRMPage;